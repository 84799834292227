footer.content-info {
  background-color: $brandPrimary;
  padding: 2rem 0;

  .container-fluid {
    @include make-col(10);
    margin: 0 auto;

    @include media-breakpoint-down(lg) {
      @include make-col(12);
      margin: 0 auto;
    }
  }

  a {
    color: black;

    &:hover {
      color: lighten(black, 10%);
    }
  }

  section.custom_html-2 {
    @include make-col(8);
    padding-right: 15px;
    padding-left: 15px;
    @include media-breakpoint-down(sm) {
      @include make-col(12);
    }
  }

  section.text-3 {
    padding-top: 2rem;

    padding-right: 15px;
    padding-left: 15px;

    @include make-col(4);
    @include media-breakpoint-down(sm) {
      @include make-col(12);
      padding-top: 0;

    }

    span {
      font-size: 0.9rem;
    }

    a {
      color: black;

      &:hover {
        color: lighten(black, 10%);
      }
    }
  }

  section {
    @include media-breakpoint-down(md) {
      margin: 1rem 0;
    }
  }
}

ul.footer-no-list {
  list-style: none;
  padding-left: 0;
}

