// Grid settings
$main-sm-columns:       10;
$sidebar-sm-columns:    4;

$brandPrimary:          rgb(184, 166, 101);

$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 40px !default;
$hamburger-layer-height                    : 4px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : rgb(184, 166, 101) !default;
$hamburger-layer-border-radius             : 0 !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
