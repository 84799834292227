.banner{
  background-color: white;
  padding: 2rem 0 2rem 0;
  width: calc(100% / 12 * 10);
  margin: 0 auto;
  z-index: 90000;

  @include media-breakpoint-down(md){
    padding: 0;
  }
}

nav.nav-primary{

  a{
    color: black;
    letter-spacing: 1px;
    padding: 0px;
    text-decoration: none;
    font-family: 'DINOTLight';
    font-size: 1.25rem;
    line-height: 22px;
    text-transform: uppercase;

    &:hover{
      color: $brandPrimary;

    }
  }

  @include media-breakpoint-down(lg) {
    opacity: 0;
    pointer-events: none;
    margin-top: -10vh;
    transition: opacity 0.35s ease-out;

    &.is-active {
      opacity: 1;
      pointer-events: all;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-top: 0;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        li {
          display: block;
          width: 100%;
          text-align: center;
          margin: 0.8rem 0;
        }
      }
    }
  }
}

.hamburger {
  position: fixed;
  top: 0;
  right: 0;

  &:focus {
    outline: 0;
    border: 0;
  }
}