.header-image {
  width: 100vw;
  height: 86vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .images {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;

      &.active {
        z-index: 3;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    height: 42vh;
  }

  .white-logo {
    width: 40vw;
    z-index: 6000;
    position: relative;

    @include media-breakpoint-down(sm) {
      width: 80vw;
    }
  }


}

.cont-360 {
  margin: 4rem 0;
}
.feat-image-360 {
  position: relative;

  background-position: center;
  background-size: cover;
  transform: scale(1.03);
  transition: transform 0.45s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
  
  img {
    visibility: hidden;
  }

  &::before {
    content: "360°";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.45s ease-out;
    color: $brandPrimary;
    font-size: 2rem;
  }

  &::after {
    content: url(../images/refresh.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    transition: all 0.45s ease-out;

    @include media-breakpoint-down(sm) {
      width: 150px;
      height: 150px;
    }
  }

  &:hover {
    &::after {
      transform: rotate(360deg) translate(-50%, -50%);
    }
  }
}

.feat-360-content {
  margin-bottom: 3rem;
  h1 {
    margin-bottom: 2rem;
  }
  > p {
    margin-bottom: 2rem;
  }
  &.flipped {
    order: 1;
  }
}

.feat-360 {
  &.flipped {
    order: 0;
  }
}

.feat-stil-content {
  h1 {
    margin: 2rem 0;
  }
}

.partner-container {
  .col-6 {
    &:first-child {
      padding-top: 8rem;
    }
  }
}

.featured-city-info {
  h1:not(:first-child) {
    margin: 3rem 0 1rem;
  }
}

.feat-partner-content {
  margin-bottom: 3rem;
}

.feat-partner img {
  max-width: 190px;
  @include media-breakpoint-down(lg) {
    max-width: 170px;
  }
}

.baumat-block {
  margin-bottom: 3rem;
  h1 {
    margin-bottom: 1rem;
  }
}

.baumat-feat {

  h1 {
    margin-bottom: 1rem;
  }

  .feat {
    margin-bottom: 3rem;
    display: block;
  }
}

.house-overlay {
  position: absolute;
  top: 0;
  right: 15px;
  left: 15px;
  bottom: 15px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.25s ease-in-out;

    &.active {
      opacity: 1;
    }
  }
}

body.page-id-312,
body.page-id-313{
  main {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}