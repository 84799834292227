body {
  position: relative;
  font-family: 'DINOTLight';
}

main.main{
  margin: 0 auto;
}

@font-face {
  font-family: 'DINOTBlack';
  src: url('../fonts/DINOTBlack.eot');
  src: url('../fonts/DINOTBlack.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINOTBlack.woff2') format('woff2'),
  url('../fonts/DINOTBlack.woff') format('woff'),
  url('../fonts/DINOTBlack.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'DINOTLight';
  src: url('../fonts/DINOTLight.eot');
  src: url('../fonts/DINOTLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/DINOTLight.woff2') format('woff2'),
  url('../fonts/DINOTLight.woff') format('woff'),
  url('../fonts/DINOTLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

h1{
  font-family: 'DINOTLight';
  font-size: 1.6rem;
  margin: 1rem 0;

  @include media-breakpoint-down(sm){
    font-size: 1.6rem;
  }
}

.logo-container-fix,
.logo-container{
  width: 20%;
  height: auto;
  margin: 2rem auto 0;
  z-index: 2000;
  position: relative;

  &.scale-2{
    width: 40%;
  }

  &.scale-4{
    width: 60%;

    @include media-breakpoint-down(md) {
      width: 40%;
    }
  }
}

.logo-type-container{
  width: 55%;
  height: auto;
  margin: 2rem auto 5rem;
}

p{
  font-family: 'DINOTLight';
}

table{
  font-family: 'DINOTLight';
}

.quotation{
  font-size: 1.9rem;
  font-family: 'DINOTLight';
  word-break: break-word;
  hyphens: auto;
  margin-bottom: 2rem;

  @include media-breakpoint-down(md) {
    font-size: 1.4rem;
  }
}

section{
  margin: 6rem 0;

  @include media-breakpoint-down(lg) {
    margin: 3rem 0;
  }
}

.my-k5 {
  margin-top: 5rem;
  margin-bottom: 5rem;

  @include media-breakpoint-down(lg) {
    margin-top: 1.4rem;
    margin-bottom: 3rem;
  }
}

.mb-4-2 {
  margin-bottom: 5rem;

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }

}

.double-height {
  height: 50%;

  @include media-breakpoint-down(md) {
    height: initial;
  }
}


section#home {
  p {
    padding-right: 4rem;

    @include media-breakpoint-down(md) {
      padding-right: 0;
    }
  }
}

a {

  color: black;
  &:hover{
    color: $brandPrimary;
  }
}

.ovh {
  overflow: hidden;

}

.par1 {
  background-size: cover;
  background-repeat: no-repeat;
}

.parh {
  width: 100%;
  height: 100%;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;

  &.full {
    background-size: cover;
    background-position: center center;
  }

  img {
    visibility: hidden;
  }

  @include media-breakpoint-down(sm) {
    img {
      visibility: visible;
    }
  }

}

.parc,
.parb {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;

  img {
    visibility: hidden;
  }

  @include media-breakpoint-down(sm) {
    img {
      visibility: visible;
    }
  }

}